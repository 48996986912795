<template>
  <div
    v-if="withBackdrop"
    class="fixed inset-0 z-50 pointer-events-auto bg-[#00000055]"
    @click="onBackdropClick"
  />
  <nav
    v-if="!isMobile || isSideBarOpen"
    class="fixed top-0 bottom-0 bg-surface w-70 z-50 flex flex-col justify-between py-5"
    :class="[
      navContainerClass,
      isRightSide ? 'right-0' : 'left-[min(0, calc(50%-50rem))]',
    ]"
  >
    <slot name="content"></slot>
    <slot name="sidebar-bottom"></slot>
  </nav>
</template>

<script setup lang="ts">
import { useIsMobile } from '~/composables/use-is-mobile';
import { useVModel } from '@vueuse/core';

type Props = {
  isOpen: boolean;
  navContainerClass?: string | Record<string, unknown>;
  isRightSide?: boolean;
  withBackdrop?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits(['onBackdropClick']);
const isMobile = useIsMobile();
const isSideBarOpen = useVModel(props, 'isOpen', emit);
const onBackdropClick = () => emit('onBackdropClick');
</script>
